var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Date")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",staticStyle:{"background":"transparent"},attrs:{"config":{
                    enableTime: false,
                    dateFormat: 'd/m/Y',
                    maxDate: new Date(),
                  }},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Employee Name")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Employee Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"fullname","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"placeholder":"None","options":_vm.employeeoption},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                  var fullname = ref.fullname;
                  var profile_image = ref.profile_image;
                  var username = ref.username;
                  var color = ref.color;
return [_c('b-avatar',{attrs:{"src":_vm.getprofileImage(profile_image)}}),_c('span',{class:'font-weight-bolder text-' + color},[_vm._v(" "+_vm._s(fullname)+" ("+_vm._s(username)+")")])]}}],null,true),model:{value:(_vm.employee),callback:function ($$v) {_vm.employee=$$v},expression:"employee"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Branch")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Branch"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"placeholder":"None","options":_vm.branchoption},model:{value:(_vm.branch),callback:function ($$v) {_vm.branch=$$v},expression:"branch"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticStyle:{"width":"100%","overflow":"auto"}},[_c('table',{staticClass:"table table-bordered",staticStyle:{"width":"28.9cm"}},[_c('thead',{staticClass:"text"},[_c('tr',[_c('th',[_vm._v("Sr no")]),_c('th',[_vm._v(" Product Name"),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")])]),_c('th',[_vm._v("Status")]),_c('th',[_vm._v("Remarks")]),_c('th',{staticStyle:{"width":"1cm"}},[_vm._v("Action")])])]),_c('tbody',_vm._l((_vm.products),function(item,id){return _c('tr',{key:id},[_c('td',[_vm._v(_vm._s(id + 1))]),_c('td',[_c('validation-provider',{attrs:{"rules":"required","name":"Product Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","placeholder":"None","options":_vm.productoption},model:{value:(item.product),callback:function ($$v) {_vm.$set(item, "product", $$v)},expression:"item.product"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('td',[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","placeholder":"None","options":['Assigned', 'Pending']},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})],1),_c('td',[_c('b-form-input',{attrs:{"placeholder":"Enter Remarks"},model:{value:(item.remarks),callback:function ($$v) {_vm.$set(item, "remarks", $$v)},expression:"item.remarks"}})],1),_c('td',[_c('b-button',{attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeRow(id)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1)])}),0)])]),_c('b-button',{staticClass:"my-1",attrs:{"variant":"primary"},on:{"click":_vm.addRow}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add More")])],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-4",attrs:{"variant":"primary","type":"submit","disabled":_vm.flag},on:{"click":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_vm._v(" Submit ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.onClickBack}},[_vm._v("Back ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
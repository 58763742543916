 <template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="6">
            <b-form-group>
              <label>Date</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Date"
              >
                <flat-pickr
                  v-model="date"
                  class="form-control"
                  :config="{
                    enableTime: false,
                    dateFormat: 'd/m/Y',
                    maxDate: new Date(),
                  }"
                  style="background: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Employee Name</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Employee Name"
              >
                <v-select
                  v-model="employee"
                  label="fullname"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="None"
                  :options="employeeoption"
                >
<template
                    #option="{ fullname, profile_image, username, color }"
                  >
                    <b-avatar :src="getprofileImage(profile_image)" />
                    <span :class="'font-weight-bolder text-' + color">
                      {{ fullname }} ({{ username }})</span
                    >
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Branch</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Branch"
              >
                <v-select
                  v-model="branch"
                  label="name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="None"
                  :options="branchoption"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <div style="width: 100%; overflow: auto">
              <table class="table table-bordered" style="width: 28.9cm">
                <thead class="text">
                  <tr>
                    <th>Sr no</th>
                    <th>
                      Product Name<label style="color: red !important">*</label>
                    </th>
                    <th>Status</th>
                    <th>Remarks</th>
                    <th style="width: 1cm">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, id) in products" :key="id">
                    <td>{{ id + 1 }}</td>
                    <td>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Product Name"
                      >
                        <v-select
                          v-model="item.product"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          placeholder="None"
                          :options="productoption"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </td>
                    <td>
                      <v-select
                        v-model="item.status"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        placeholder="None"
                        :options="['Assigned', 'Pending']"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.remarks"
                        placeholder="Enter Remarks"
                      />
                    </td>

                    <td>
                      <b-button variant="outline-danger" @click="removeRow(id)">
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button variant="primary" @click="addRow" class="my-1">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm($event)"
              class="mr-4"
              :disabled="flag"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- <template #code>
      {{ codeType }}
    </template> -->
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BInputGroupAppend,
  BCol,
  BInputGroup,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BAvatar
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axios from '@/components/axios';
import moment from "moment";

export default {
  components: {
    flatPickr,
    PinchScrollZoom,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BAvatar

  },
  data() {
    return {
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      date: moment(new Date()).format("DD/MM/yyyy"),
      imagePath: process.env.VUE_APP_IMAGE_PATH,

      // nextfollowupdate: moment(new Date()).format("DD/MM/yyyy"),
      employee: "",
      employeeoption: [],
      branch: "",
      branchoption: [],
      productoption: [],
      ifEdit: "",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      id: "",
      products: [
        {
          product: "",
          status: "",
          remarks: "",
        },
      ],
      flag: false,

    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Asset Tracker") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/hr/assettracker");
            }
          } else if (item.add !== 1) {
            this.$router.push("/hr/assettracker");
          }
        }
      });
    }
    this.init();
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    getprofileImage(image) {
      if (image !== undefined && image !== null && image !== "") {
        let test = image.includes(this.imagePath)
          ? image
          : this.imagePath + image;
        return test;
      }
      return image;
    },
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getassettrackerById/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          // if (json.data.length) {
          // }
          this.getEditItems(json.data.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      item.map((item) => {
        this.products = [];
        this.date = item.date;
        this.employee = item.employee;
        this.branch = item.branch;
        this.test = item.AssettracketList;
        this.test.map((item) => {
          this.products.push({
            ProductName: item.product,
            status: item.status,
            remarks: item.remarks,
            product: item.ProductName,
          });
        });
      });
    },
    init() {
      this.getUser();
      this.getBranch();
      this.assettracker();
    },
    async getUser(id) {
      if (id !== undefined) {
        this.id = id;
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getEmployee`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.employeeoption = response.data.data;
          this.employeeoption.map((item) => {
            if (item.id == id) {
              this.employee = item.name;
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    async getBranch(id) {
      if (id !== undefined) {
        this.id = id;
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/branch`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.branchoption = response.data.data.data;
          this.branchoption.map((item) => {
            if (item.id == id) {
              this.branch = item.name;
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    async assettracker(id) {
      if (id !== undefined) {
        this.id = id;
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getassetmaster`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.productoption = response.data.data;
          this.productoption.map((item) => {
            if (item.id == id) {
              this.name = item.name;
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    onClickBack() {
      this.$router.push("/hr/assettracker");
    },
    submitForm(e) {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == true) {
          this.handleProduct();
        }
        const data = {
          date: this.date,
          employee: this.employee.id,
          branch: this.branch.id,
          data: this.products,
        };
        // var ifEdit = this.$route.params.id ? true : false;
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success == true) {
          this.flag = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/assettracker/${this.$route.params.id}`
              : `${baseApi}/assettracker`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.flag = false;
              this.$swal({
                title: "Submited",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                  ? `${json.data.success}`
                  : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.$router.push("/hr/assettracker");
            })
            .catch((error) => {
              this.flag = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    handleProduct() {
      if (this.products !== 0) {
        this.products.map((item) => {
          if (!item.ProductName) {
            item.product = item.product.id;
          } else if (item.product.id) {
            const x = item.product;
            item.product = x.id;
            item.ProductName = x.name;
          } else {
            const y = item.product;
            item.product = item.ProductName;
            item.ProductName = y;
          }
        });
      }
    },
    addRow() {
      this.products.push({
        product: "",
        status: "",
        remarks: "",
      });
    },
    removeRow(index) {
      this.products.splice(index, 1);
    },
  },
};
</script>

